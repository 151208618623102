html {
    scroll-behavior: smooth;
}

html,body {
    font-size: 16px;
}

.navbar {
    min-height: 64px;
    @media (min-width: 768px) {
      min-height: 100px;
    }
}

.react-bootstrap-typeahead {
    .dropdown-item {
        &.disabled {
            text-decoration: none;
        }
    }
}

.react-bootstrap-typeahead-desktop {
    .dropdown-menu {
        width:fit-content !important;
        left:0 !important;
        min-width: 100% !important;

        * {
            font-size: 0.875rem !important;
            line-height: 1.25rem !important;
            text-decoration: none;
        }
    }
   
}

.navbar-nav{
    .nav-item.dropdown:hover .dropdown-menu {
        display: block;
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease-in-out;
    }

    .dropdown-menu {
        width:fit-content !important;
        display: none;
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }
}


.password-toggle {
    .form-control {
        padding-right: 2.75rem;
    }
    span {
        right: 0.875rem;
        top: calc(50% - 0.75rem);
    }
}

.custom-tooltip {
    max-width: 100vw;
    .tooltip-inner {
        max-width: unset;
        text-align: left;
    }
    
    box-shadow: $box-shadow-sm !important;
}

.shadow-border-bottom {
    box-shadow: 0px 1px 0px 0px #EBEEF0;
  }

.bg-hover-white:hover, .bg-hover-white:focus {
    background-color: #fff !important;
  }
  
.bg-img-none{
    background-image: none;
    background: none;
}

.flex-basis-325 {
    flex-basis: 3.25rem;
}
.flex-basis-350 {
    flex-basis: 3.5rem;
}

.result-messages {
    z-index: 50;
}

.border-style-dashed {
    border-style: dashed !important;
}

.leaflet-div-icon {
    border: none;
}
.text-decoration-underline {
    text-decoration: underline;
}

.mix-blend-multiply {
    mix-blend-mode:multiply !important;
}

.form-select-lg {
    height: 4rem;     
}

.progress {
    height: 0.25rem !important; 
}

.select2-container {
    .select2-selection--multiple {
        display: flex;
    }
} 

.rotate-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.rotate-270 {
    transform: rotate(270deg);
}

.flag {
    &.small {
        width: 1.5rem;
    }

    &.large {
        width: 2rem;
        height: 1.5rem;
    }
}

.iti--show-flags {
    width:100%
}



.row-max-height-0 {
    max-height:0px;
}

@media (min-width: $grid-breakpoint-md) {
    .row-max-height-md-auto {
        max-height:unset;
    }
}

.key {
    width:1rem;
    height:1rem;
    border-radius: 50%;
}






.search-query-right {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path fill="#FFF" stroke="#FFF" d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"/></svg>');
    background-position: center right 0.5rem;
    background-size:  0.875rem;   
    padding-left: 2.5rem;
    background-repeat: no-repeat;
}


.form-control-lg {
    &.search-query-right {
        background-position: center right 1rem;
        background-size:  1rem;   
        padding-right: 2.5rem;
    }

}
.disc-1 {
    width:$spacer * .25;
    height:$spacer * .25;
}

.disc-2 {
    width:$spacer * .5;
    height:$spacer * .5;
}

.disc-3 {
    width:$spacer;
    height:$spacer;
}

.disc-4 {
    width:$spacer * 1.5;
    height:$spacer * 1.5;
}

.disc-5 {
    width:$spacer * 2;
    height:$spacer * 2;
}

.disc-6 {
    width:$spacer * 3;
    height:$spacer * 3;
}

.disc-7 {
    width:$spacer * 4;
    height:$spacer * 4;
}

.disc-8 {
    width:$spacer * 5;
    height:$spacer * 5;
}

.disc-9 {
    width:$spacer * 6;
    height:$spacer * 6;
}

.disc-10 {
    width:$spacer * 7;
    height:$spacer * 7;
}

.disc-11 {
    width:$spacer * 8;
    height:$spacer * 8;
}

.disc-12 {
    width:$spacer * 9;
    height:$spacer * 9;
}

.disc-13 {
    width:$spacer * 10;
    height:$spacer * 10;
}

.disc-14 {
    width:$spacer * 11;
    height:$spacer * 11;
}

.disc-15 {
    width:$spacer * 12;
    height:$spacer * 12;
}

.disc-16 {
    width:$spacer * 13;
    height:$spacer * 13;
}

.disc-17 {
    width:$spacer * 14;
    height:$spacer * 14;
}

.disc-18 {
    width:$spacer * 15;
    height:$spacer * 15;
}

.disc-19 {
    width:$spacer * 16;
    height:$spacer * 16;
}

.disc-20 {
    width:$spacer * 17;
    height:$spacer * 17;
}

.disc-badge {
    width:2.5rem;
    height:2.5rem;
}

.rounded-bottom-0 {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.rounded-bottom-left-0 {
    border-bottom-left-radius: 0px !important;    
}
.rounded-bottom-right-0 {
    border-bottom-right-radius: 0px !important;
}
.rounded-top-0 {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
}

.rounded-top-left-0,
.rounded-top-start-0 {
    border-top-left-radius: 0px !important;    
}
.rounded-top-right-0,
.rounded-top-end-0 {
    border-top-right-radius: 0px !important;
}
.rounded-bottom-left-0,
.rounded-bottom-start-0 {
    border-bottom-left-radius: 0px !important;    
}
.rounded-bottom-right-0,
.rounded-bottom-end-0 {
    border-bottom-right-radius: 0px !important;
}
.fa-disc-3{
    width:1.563rem;
    height:1.563rem;
}
.no-psuedo-after {
    &:after {
        display: none;
    }
}

.no-results-icon {
    width:5.625rem;
    height:5.625rem;
    top:-.75rem;
    i {
        font-size: 3rem;
        line-height: 3rem;
    }
}

.dropdown-menu-w{
    width: 20rem !important;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

@media (min-width: 576px) {
    .modal-dialog {
     
      margin: 23px auto;
    }
  }

@media (min-width: $grid-breakpoint-lg) {
    .overflow-y-lg-scroll {
        overflow-y: scroll;
    }
    
    .overflow-y-lg-auto {
        overflow-y: auto;
    }
}

.contour-29-center-top {
    @include contour-bg-shared;
    @include contour-bg-rotate-180;
    @include contour-29-zero-dark;
    @include contour-position-left-100-top;
    @include contour-background-cover;
    @include contour-bg-opacity-15;
   
}


.contour-9-center-top {
    @include contour-9-zero-dark;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 200%;
    opacity: 0.25;
}


.contour-29-center-top-and-bottom{
    @include contour-bg-shared-after;
    @include contour-29-zero-dark-after;
    @include contour-position-left-100-top-after;
    @include contour-background-cover-after;
    @include contour-bg-opacity-15-after;
}

.contour-01-center-top {
    @include signup-contour-bg-shared;
    @include signup-contour-bg-rotate-180;
    @include signup-contour-01-zero-dark-50;
    @include signup-contour-position-left-100-top;
    @include signup-contour-background-cover;
    @include signup-contour-bg-opacity-06;
   
}

.contour-01-center-top-and-bottom{
    @include signup-contour-bg-shared;
    @include signup-contour-bg-rotate-180;
    @include signup-contour-30-zero-dark;
    @include signup-contour-position-left-100-top;
    @include signup-contour-background-cover;
    @include signup-contour-bg-opacity-7;
    @include signup-contour-bg-shared-after;
    @include signup-contour-30-zero-dark-after;
    @include signup-contour-position-left-100-top-after;
    @include signup-contour-background-cover-after;
    @include signup-contour-bg-opacity-7-after;
}


.contour-30-center-top {
    @include signup-contour-bg-shared;
    @include signup-contour-bg-rotate-180;
    @include signup-contour-30-zero-dark;
    @include signup-contour-position-left-100-top;
    @include signup-contour-background-cover;
    @include signup-contour-bg-opacity-15;
   
}

.contour-30-center-top-and-bottom{
    @include signup-contour-bg-shared;
    @include signup-contour-bg-rotate-180;
    @include signup-contour-30-zero-dark;
    @include signup-contour-position-left-100-top;
    @include signup-contour-background-cover;
    @include signup-contour-bg-opacity-7;
    @include signup-contour-bg-shared-after;
    @include signup-contour-30-zero-dark-after;
    @include signup-contour-position-left-100-top-after;
    @include signup-contour-background-cover-after;
    @include signup-contour-bg-opacity-7-after;
}

.group-center-top-and-bottom{
    @include group-bg-shared;
    @include group-bg-shared-after;
}

.contour-background {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    &.overlay-contour-30-center-top {
        @include bg-contour-30;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;       
        opacity: 0.07;
        transform: rotate(180deg);
    }
    &.overlay-contour-03-center-top {
        @include bg-contour-03;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;       
        opacity: 0.07;
        transform: rotate(180deg);
    }
    &.overlay-contour-03-center-top-cover {
        @include bg-contour-03;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: cover;       
        opacity: 0.07;
        transform: rotate(180deg);
    }
    &.overlay-contour-30-center-bottom {
        @include bg-contour-30;
        background-position: bottom center;
        background-repeat: no-repeat;
        background-size: contain;       
        opacity: 0.07;
    }
    &.overlay-contour-31-200-percent-bottom-left {
        @include bg-contour-31;
        background-position: left 0rem bottom -1rem;
        background-repeat: no-repeat;
        background-size: 335%;
        opacity: 0.07;
    }
}

.accept-invite-card-contour {
    @include login-contour-white-color-4;
    @include login-contour-white-position-center-bottom;
    @include login-contour-white-background-cover;
    @include accept-invite-white-background-img;
    @include login-contour-white-background-img-opacity;
    height: auto;
    &.cover {
        @include login-contour-white-background-cover;
    }

    &.contain {
        @include login-contour-white-background-contain;
    }

    &.bg-4 {
        @include login-contour-white-color-4;
    }
}

.login-card-contour {
    @include login-contour-white-color-4;
    @include login-contour-white-position-center-bottom;
    @include login-contour-white-background-cover;
    @include login-contour-white-background-img;
    @include login-contour-white-background-img-opacity;
    height: auto;
    &.cover {
        @include login-contour-white-background-cover;
    }

    &.contain {
        @include login-contour-white-background-contain;
    }

    &.bg-4 {
        @include login-contour-white-color-4;
    }
}
.forget-card-contour {
    @include login-contour-white-color-4;
    @include login-contour-white-position-center-bottom;
    @include login-contour-white-background-cover;
    @include login-contour-white-background-img;
    @include forget-contour-white-background-img-opacity;
    height: auto;
    &.cover {
        @include login-contour-white-background-cover;
    }

    &.contain {
        @include login-contour-white-background-contain;
    }

    &.bg-4 {
        @include login-contour-white-color-4;
    }
}
.forget-thankyou-card-contour {
    @include login-contour-white-color-4;
    @include login-contour-white-position-center-bottom;
    @include login-contour-white-background-cover;
    @include forget-thankyou-contour-white-background-img;
    @include forget-contour-white-background-img-opacity;
    height: auto;
    &.cover {
        @include login-contour-white-background-cover;
    }

    &.contain {
        @include login-contour-white-background-contain;
    }

    &.bg-4 {
        @include login-contour-white-color-4;
    }
}
.newpassword-card-contour {
    @include login-contour-white-color-4;
    @include login-contour-white-position-center-bottom;
    @include login-contour-white-background-cover;
    @include newpassword-contour-white-background-img;
    @include forget-contour-white-background-img-opacity;
    height: auto;
    &.cover {
        @include login-contour-white-background-cover;
    }

    &.contain {
        @include login-contour-white-background-contain;
    }

    &.bg-4 {
        @include login-contour-white-color-4;
    }
}

.z-index-n1{
    z-index: -1;
}
.z-index-1 {
    z-index: 1;
}
.z-index-2 {
    z-index: 2 !important;
}

.fi-emblem-white {
    @include fi-emblem-white;
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
    
}


.fi-emblem-white-before {
    
    &::before {
        @include fi-emblem-white;
        content: ' ';       
        display: block;
        background-size: cover;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }
    
}

.header-mobile-menu,
.header-profile-menu {
    &:not( .mm-menu ) { display: none; } 
}

.zero-dark-tab-link {
    border-color : $zero-dark-500 !important;
    background-color: $white !important;
    color: $zero-dark-500 !important;
    &:hover,
    &.active {
        background-color : $zero-dark-500 !important;
        color: $white !important;
    }
}
@media (max-width: 767px) {
    .sm-border-top-left-radius{
        border-top-left-radius:  1.5rem !important;
    }
    .sm-border-top-right-radius{
        border-top-right-radius:  1.5rem !important;
    }    
}

.w-px-850{
    width: 850px !important;
}



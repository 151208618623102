// This mixin uses an `if()` technique to be compatible with Dart Sass
// See https://github.com/sass/sass/issues/1873#issuecomment-152293725 for more details

// scss-docs-start form-validation-mixins
@mixin form-validation-state-selector($state) {
  @if ($state == "valid" or $state == "invalid") {
    .was-validated #{if(&, "&", "")}:#{$state},
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  } @else {
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  }
}

@mixin form-validation-state(
  $state,
  $color,
  $color-focus-calc: if($state == 'invalid', $fio-error-300 ,$secure-blue-300),
  $color-feedback-calc: if($state == 'invalid', $fio-error-500 ,$fio-success-500),
  $border-color-feedback-calc: if($state == 'invalid', $fio-error-500 ,$zero-dark-100),
  $box-shadow-feedback-calc: 0 0 0 $input-focus-width if($state == 'invalid', $fio-error-50 ,transparent ),
  $icon,
  $tooltip-color: color-contrast($color),
  $tooltip-bg-color: rgba($color, $form-feedback-tooltip-opacity),
  $focus-box-shadow-calc: 0 0 0 $input-focus-width if($state == 'invalid', $fio-error-50 ,$secure-blue-50),
  $focus-box-shadow: 0 0 0 $input-focus-width rgba($color, $input-btn-focus-color-opacity)
) {
  .#{$state}-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;   
    color: $color-feedback-calc;
    a {
      color: $color-feedback-calc;
    }
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
    margin-top: .1rem;
    @include font-size($form-feedback-tooltip-font-size);
    line-height: $form-feedback-tooltip-line-height;
    color: $tooltip-color;
    background-color: $tooltip-bg-color;
    @include border-radius($form-feedback-tooltip-border-radius);
  }

  @include form-validation-state-selector($state) {
    ~ .#{$state}-feedback,
    ~ .#{$state}-tooltip {
      display: block;
    }
    ~ .toggle-password {
      top: calc(50% - 0.75rem);
    }
  }

  .form-control {

   
    @include form-validation-state-selector($state) {
      border-color: $border-color-feedback-calc !important;
      //box-shadow: $box-shadow-feedback-calc;

      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-image: escape-svg($icon);
        background-repeat: no-repeat;       
        background-size: $input-height-inner-half $input-height-inner-half;
        background-position: right $input-padding-x-sm top $input-padding-x-sm;     
      }

      &:focus,
      &:active {
        border-color: $color-focus-calc;
        box-shadow:  $focus-box-shadow-calc;
      }
    
    }
    &.form-control-sm {   
      @include form-validation-state-selector($state) {
        @if $enable-validation-icons {        
          background-position: right $input-padding-x-sm top $input-padding-x-sm;   
          &:has(~ .toggle-password)  {
            background-position: right $input-padding-x-sm * 3.5 top $input-padding-x-sm;   
          }      
        }
      }
    }
    &.form-control-md {   
      @include form-validation-state-selector($state) {
        @if $enable-validation-icons {        
          background-position: right $input-control-padding-x-md top $input-control-padding-x-md;   
          &:has(~ .toggle-password)  {
            background-position: right $input-control-padding-x-md * 3.5  top $input-control-padding-x-md;   
          }  
        }
      }
    }  
    &.form-control-lg {   
      @include form-validation-state-selector($state) {
        @if $enable-validation-icons {        
          background-position: right $input-padding-x-lg top $input-padding-x-lg;   
          &:has(~ .toggle-password)  {
            background-position: right $input-padding-x-lg * 3.5 top $input-padding-x-lg   
          }       
        }
      }
    }
  }
  

  // stylelint-disable-next-line selector-no-qualifying-type
  textarea.form-control {
    @include form-validation-state-selector($state) {
      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
      }
    }
  }

  .form-select {
    @include form-validation-state-selector($state) {
      border-color: $color;

      @if $enable-validation-icons {
        &:not([multiple]):not([size]),
        &:not([multiple])[size="1"] {
          padding-right: $form-select-feedback-icon-padding-end;
          background-image: escape-svg($form-select-indicator), escape-svg($icon);
          background-position: $form-select-bg-position, $form-select-feedback-icon-position;
          background-size: $form-select-bg-size, $form-select-feedback-icon-size;
        }
      }

      &:focus {
        border-color: $color;
        box-shadow: $focus-box-shadow;
      }
    }
  }

  .form-control-color {
    @include form-validation-state-selector($state) {
      @if $enable-validation-icons {
        width: add($form-color-width, $input-height-inner);
      }
    }
  }

  .form-check-input {
    @include form-validation-state-selector($state) {

      @if ($state == "valid") {

        &:not(.dont-highlight-valid){
          border-color: $color;

          &:focus {
            box-shadow: $focus-box-shadow;
          }

          ~ .form-check-label {
            color: $color;
            a {
              color: $color;
            }
          }

          &:checked {
            background-color: $color;
          }   
        }
      } @else {
        border-color: $color;

        &:focus {
          box-shadow: $focus-box-shadow;
        }

        ~ .form-check-label {
          color: $color;
          a {
            color: $color;
          }
        }

        &:checked {
          background-color: $color;
        }   
      }
      
    }
  }
  .form-check-inline .form-check-input {
    ~ .#{$state}-feedback {
      margin-left: .5em;
    }
  }

  .input-group {
    > .form-control:not(:focus),
    > .form-select:not(:focus),
    > .form-floating:not(:focus-within) {
      @include form-validation-state-selector($state) {
        @if $state == "valid" {
          z-index: 3;
        } @else if $state == "invalid" {
          z-index: 4;
        }
      }
    }
  }
}
// scss-docs-end form-validation-mixins

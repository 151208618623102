.toast {
  // scss-docs-start toast-css-vars
  --#{$prefix}toast-zindex: #{$zindex-toast};
  --#{$prefix}toast-padding-x: #{$toast-padding-x};
  --#{$prefix}toast-padding-y: #{$toast-padding-y};
  --#{$prefix}toast-spacing: #{$toast-spacing};
  --#{$prefix}toast-max-width: #{$toast-max-width};
  
  --#{$prefix}toast-btn-close-bg-color: #{$toast-color};
  --#{$prefix}toast-color: #{$toast-color};
  --#{$prefix}toast-bg: #{$toast-background-color};
  --#{$prefix}toast-border-width: #{$toast-border-width};
  --#{$prefix}toast-border-color: #{$toast-border-color};
  --#{$prefix}toast-border-radius: #{$toast-border-radius};
  --#{$prefix}toast-box-shadow: #{$box-shadow-xs};
  --#{$prefix}toast-header-color: #{$toast-header-color};
  --#{$prefix}toast-header-bg: #{$toast-header-background-color};
  --#{$prefix}toast-header-border-color: #{$toast-header-border-color};
  // scss-docs-end toast-css-vars

  width: var(--#{$prefix}toast-max-width);
  max-width: 100%;  
  color: var(--#{$prefix}toast-color) !important;
  pointer-events: auto;
  background-color: var(--#{$prefix}toast-bg);
  background-clip: padding-box;
  padding: var(--#{$prefix}toast-padding-y) var(--#{$prefix}toast-padding-x);

  font-size: $paragraph-extra-small-size;
  line-height: $paragraph-extra-small-height; 


  .btn-close {      
    background-image: escape-svg(url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill="#{$toast-color}"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"/></svg>'));
    background-color: transparent;

  }


  border-left: var(--#{$prefix}toast-border-width) solid var(--#{$prefix}toast-border-color);
  box-shadow: 0px 1px 0px 0px $zero-dark-50;


  @include border-radius(var(--#{$prefix}toast-border-radius));

  &.showing {
    opacity: 0;
  }

  &:not(.show) {
    display: none;
  }
}

.toast-container {
  --#{$prefix}toast-zindex: #{$zindex-toast};

  position: absolute;
  z-index: var(--#{$prefix}toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;

  > :not(:last-child) {
    margin-bottom: var(--#{$prefix}toast-spacing);
  }
}

.toast-header {
  font-weight: $font-weight-semibold;
  padding-bottom: .25rem;
  .btn-close {
    margin-right: calc(-.5 * var(--#{$prefix}toast-padding-x)); // stylelint-disable-line function-disallowed-list
    margin-left: var(--#{$prefix}toast-padding-x);
  }
}

.toast-body {
 
}

@each $state, $value in $alert-toast-bg-colors {
 
  .toast-#{$state} {
    --#{$prefix}toast-bg: #{$value};
  }
}

@each $state, $value in $alert-toast-colors {
 
  .toast-#{$state} {
    --#{$prefix}toast-color: #{$value};
    --#{$prefix}toast-btn-close-bg-color: #{$value};
    .btn-close {
      background-image: escape-svg(url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512" fill="#{$value}"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M324.5 411.1c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L214.6 256 347.1 123.5c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L192 233.4 59.5 100.9c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L169.4 256 36.9 388.5c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L192 278.6 324.5 411.1z"/></svg>'));
    }
    

  }
}

@each $state, $value in $alert-toast-colors {
 
  .toast-#{$state} {
    --#{$prefix}toast-border-color: #{$value};
  }
}


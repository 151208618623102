@media (min-width: $grid-breakpoint-sm) {
    .container-sm, .container {
        max-width: unset;
    }
}

@media (min-width: 1400px) {
    .container-xxl, 
    .container-xl, 
    .container-lg, 
    .container-md, 
    .container-sm, 
    .container {
        max-width: 1440px;
    }        
}

.btn-square {
    padding-left: 0.75rem!important;
    padding-right: 0.75rem!important;
}

.search-query {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"/></svg>');
    background-position: center left 0.5rem;
    background-size:  0.875rem;   
    padding-left: 1.75rem;
    background-repeat: no-repeat;

    &.right-icon {
        background-position: center right 0.5rem; 
        padding-left: 0.5rem;
        padding-right: 1.75rem;

    }
}
.dark {
    &.search-query {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="rgb(255, 255, 255)" ><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M368 208A160 160 0 1 0 48 208a160 160 0 1 0 320 0zM337.1 371.1C301.7 399.2 256.8 416 208 416C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208c0 48.8-16.8 93.7-44.9 129.1L505 471c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L337.1 371.1z"/></svg>');
    } 

    &.search-query-right-icon {
        background-position: center right 0.5rem;
        background-size:  0.875rem;   
        padding-right: 2.5rem;
        padding-left: 1rem;
        background-repeat: no-repeat;
    }
}
.align-self-start {
    align-self: start;
}

.touch-action svg {
    min-width:100%;
}
.datepickerstart {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z"/></svg>');
    background-position: right .75rem center;
    background-size: 1rem;
    background-repeat: no-repeat;
    padding-right: 2.25rem;
}

main {
    /*minus header and footer*/
    min-height: calc(100vh - 144px);
    &.admin {
        /*minus header */
        min-height: calc(100vh - 72px);
    }
} 
.show-on-hover-right-center {
    right: -1.25rem;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out;
}

.event-card:hover .show-on-hover-right-center {
    display: flex;
    justify-content: center;
    align-items: center;
    right: -1.25rem;
}

.show-item-on-hover-or-active {
    .item {
        display: none;
    }
}

.show-item-on-hover-or-active {
    &:hover ,
    &.active {
        .item {
            display: initial;
        }
    }   
}


.no-psuedo-after {
    &:after {
        display: none;
    }
}


.text-overflow-ellipsis
{
    text-overflow: ellipsis;
}

.overflow-y-scroll {
    overflow-y: scroll;
}
.overflow-y-auto {
    overflow-y: auto;
}
.overflow-y-hidden {
    overflow-y: hidden;
}
.overflow-x-scroll {
    overflow-x: scroll;
}
.overflow-x-auto {
    overflow-x: auto;
}
.overflow-x-hidden {
    overflow-x: hidden;
}

.z-index-1 {
    z-index: 1 !important;
}

.z-index-2 {
    z-index: 2 !important;
}

.pac-container
{
    z-index:$zindex-offcanvas; 
}

.datepicker {
    padding:0.5rem;
}

.disc-1 {
    width:$spacer * .25;
    height:$spacer * .25;
}

.disc-2 {
    width:$spacer * .5;
    height:$spacer * .5;
}

.disc-3 {
    width:$spacer;
    height:$spacer;
}

.disc-35 {
    width:$spacer * 1.25;
    height:$spacer * 1.25;
}

.disc-4 {
    width:$spacer * 1.5;
    height:$spacer * 1.5;
}
.disc-45 {
    width:$spacer * 1.75;
    height:$spacer * 1.75;
}

.disc-5 {
    width:$spacer * 2;
    height:$spacer * 2;
}
.disc-55 {
    width:$spacer * 2.5;
    height:$spacer * 2.5;
}
.disc-6 {
    width:$spacer * 3;
    height:$spacer * 3;
}
.disc-65 {
    width:$spacer * 3.5;
    height:$spacer * 3.5;
}
.disc-7 {
    width:$spacer * 4;
    height:$spacer * 4;
}

.disc-8 {
    width:$spacer * 5;
    height:$spacer * 5;
}

.disc-9 {
    width:$spacer * 6;
    height:$spacer * 6;
}

.disc-10 {
    width:$spacer * 7;
    height:$spacer * 7;
}

.disc-11 {
    width:$spacer * 8;
    height:$spacer * 8;
}

.disc-12 {
    width:$spacer * 9;
    height:$spacer * 9;
}

.disc-13 {
    width:$spacer * 10;
    height:$spacer * 10;
}

.disc-14 {
    width:$spacer * 11;
    height:$spacer * 11;
}

.disc-15 {
    width:$spacer * 12;
    height:$spacer * 12;
}

.disc-16 {
    width:$spacer * 13;
    height:$spacer * 13;
}

.disc-17 {
    width:$spacer * 14;
    height:$spacer * 14;
}

.disc-18 {
    width:$spacer * 15;
    height:$spacer * 15;
}

.disc-19 {
    width:$spacer * 16;
    height:$spacer * 16;
}

.disc-20 {
    width:$spacer * 17;
    height:$spacer * 17;
}

.disc-badge {
    width:2.5rem;
    height:2.5rem;
}

@media (min-width: $grid-breakpoint-md) { 
    .disc-md-1 {
        width:$spacer * .25 !important;
        height:$spacer * .25 !important;
    }
    
    .disc-md-2 {
        width:$spacer * .5 !important;
        height:$spacer * .5 !important;
    }

    .disc-md-25 {
        width:$spacer * .75 !important;
        height:$spacer * .75 !important;
    }
    
    .disc-md-3 {
        width:$spacer !important;
        height:$spacer !important;
    }

    .disc-md-35 {
        width:$spacer * 1.25  !important;
        height:$spacer * 1.25  !important;
    }
    
    .disc-md-4 {
        width:$spacer * 1.5 !important;
        height:$spacer * 1.5 !important;
    }

    .disc-md-45 {
        width:$spacer * 1.75 !important;
        height:$spacer * 1.75 !important;
    }
    
    .disc-md-5 {
        width:$spacer * 2 !important;
        height:$spacer * 2 !important;
    }

    .disc-md-55 {
        width:$spacer * 2.5 !important;
        height:$spacer * 2.5 !important;
    }
    
    .disc-md-6 {
        width:$spacer * 3 !important;
        height:$spacer * 3 !important;
    }
    
    .disc-md-65 {
        width:$spacer * 3.5 !important;
        height:$spacer * 3.5 !important;
    }

    .disc-md-7 {
        width:$spacer * 4 !important;
        height:$spacer * 4 !important;
    }
    
    .disc-md-8 {
        width:$spacer * 5 !important;
        height:$spacer * 5 !important;
    }
    
    .disc-md-9 {
        width:$spacer * 6 !important;
        height:$spacer * 6 !important;
    }
    
    .disc-md-10 {
        width:$spacer * 7 !important;
        height:$spacer * 7 !important;
    }
    
    .disc-md-11 {
        width:$spacer * 8 !important;
        height:$spacer * 8 !important;
    }
    
    .disc-md-12 {
        width:$spacer * 9 !important;
        height:$spacer * 9 !important;
    }
    
    .disc-md-13 {
        width:$spacer * 10 !important;
        height:$spacer * 10 !important;
    }
    
    .disc-md-14 {
        width:$spacer * 11 !important;
        height:$spacer * 11 !important;
    }
    
    .disc-md-15 {
        width:$spacer * 12 !important;
        height:$spacer * 12 !important;
    }
    
    .disc-md-16 {
        width:$spacer * 13 !important;
        height:$spacer * 13 !important;
    }
    
    .disc-md-17 {
        width:$spacer * 14 !important;
        height:$spacer * 14;
    }
    
    .disc-md-18 {
        width:$spacer * 15 !important;
        height:$spacer * 15 !important;
    }
    
    .disc-md-19 {
        width:$spacer * 16 !important;
        height:$spacer * 16 !important;
    }
    
    .disc-md-20 {
        width:$spacer * 17 !important;
        height:$spacer * 17 !important;
    }
    
    .disc-md-badge {
        width:2.5rem  !important;
        height:2.5rem !important;
    }
}

 .z-index-n1 {
    z-index: -1;
  }
  
  .z-index-1 {
    z-index: 1;
  }
  
  .z-index-2 {
    z-index: 2 !important;
  }

.center-content {
    @include center-content;
}



.rounded-top-left-0,
.rounded-top-start-0 {
    border-top-left-radius: 0px !important;    
}
.rounded-top-right-0,
.rounded-top-end-0 {
    border-top-right-radius: 0px !important;
}
.rounded-bottom-left-0,
.rounded-bottom-start-0 {
    border-bottom-left-radius: 0px !important;    
}
.rounded-bottom-right-0,
.rounded-bottom-end-0 {
    border-bottom-right-radius: 0px !important;
}



.flex-1 {
    flex: 1;
}

.btn-group {
    &.focus {     
        &.active,
        &:has(.form-control:focus),
        &:has(.form-control:active) {
            @if $enable-shadows {
                @include box-shadow($input-box-shadow, $input-focus-box-shadow);
              } @else {
                // Avoid using mixin so we can pass custom focus shadow properly     
                box-shadow: 0 0 0 $input-focus-width $secure-blue-50 ;
              } 
        }
        .form-control {
            box-shadow: none !important;
            &:focus,
            &:active {
                &~.btn {
                    color: $input-focus-color;
                    background-color: $input-focus-bg;
                    
                    outline: 0;
                    
                }
            }
            
        }
    }
}
.btn-group {
    &.focus-dark {     
        border-radius: $border-radius-pill;
        &.active,
        &:has(.form-control:focus),
        &:has(.form-control:active) {
            @if $enable-shadows {
                @include box-shadow($input-box-shadow, $input-focus-box-shadow);
              } @else {
                // Avoid using mixin so we can pass custom focus shadow properly     
                box-shadow: 0 0 1rem .25rem rgba(244, 24, 88, 0.25);
              }
        }
        .form-control {
            box-shadow: none !important;
            &:focus,
            &:active {
                &~.btn {
                    color: $input-focus-color;
                    background-color: $input-focus-bg;
                    
                    outline: 0;
                    
                }
            }
            
        }
    }
}


.form-control {
    &.dark {
    
        &:focus,
        &:active {
          @if $enable-shadows {
            @include box-shadow($input-box-shadow, $input-focus-box-shadow);
          } @else {
            // Avoid using mixin so we can pass custom focus shadow properly     
            box-shadow: 0 0 1rem .25rem rgba(244, 24, 88, 0.25) !important;
          }
        }
      }
}
.d-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 3rem;
    /* justify-items: center; */
    margin: 0;
    padding: 0;
    grid-auto-rows: 1fr;

    .grid-loader {
        grid-column: 1 / span 1;
    }
    
}
.grid-autofill {
    grid-template-columns: repeat(auto-fill, minmax(25ch, 1fr));
}
.grid-2-cols {
    grid-template-columns: repeat(2, 1fr) !important;
    .grid-loader {
        grid-column: 1 / span 2 !important;
    }
}

.column-span-2 {
    grid-column: 1 / span 2
}
.column-span-3 {
    grid-column: 1 / span 3
}
.fixed-1-and-5-row {
    grid-template-rows: 4.5rem 1fr 1fr 1fr 2.25rem;
} 
.fixed-1-row-bigger {
    grid-template-rows: 4.5rem 1fr;
}
.fixed-1-row {
    grid-template-rows: 2.5rem 1fr;
}
.fixed-1-row-3 {
    grid-template-rows: 1rem 1fr;
}
.fixed-1-row-4 {
    grid-template-rows: 1.5rem 1fr;
}
.fixed-1-row-5 {
    grid-template-rows: 2rem 1fr;
}
.fixed-1-row-6 {
    grid-template-rows: 3rem 1fr;
}
.fixed-1-row-7 {
    grid-template-rows: 4rem 1fr;
}
.fixed-1-and-2-row-3 {
    grid-template-rows: 1rem;
}
.fixed-1-and-2-row-4 {
    grid-template-rows: 1.5rem ;
}
.fixed-1-and-3-row-3 {
    grid-template-rows: 1rem  1fr 1rem;
}
.fixed-1-and-3-row-4 {
    grid-template-rows: 1.5rem 1fr 1.5rem ;
}
.fixed-1-and-4-row-3 {
    grid-template-rows: 1rem  1fr  1fr 1rem;
}
.fixed-1-and-4-row-4 {
    grid-template-rows: 1.5rem 1fr  1fr 1.5rem ;
}
.fixed-1-and-5-row-3 {
    grid-template-rows: 1.5rem 1fr  1fr  1fr 1rem;
}
.fixed-1-and-5-row-4 {
    grid-template-rows: 1.5rem 1fr  1fr  1fr 1.5rem;
}

.fixed-1-row-4 {
    grid-template-rows: 1.5rem 1fr;
}

.fixed-1-row-smaller {
    grid-template-rows: 1rem 1fr;
}
.fixed-4-row {
    grid-template-rows: 1fr 1fr 1fr 2.25rem;
} 
.fixed-5-plus-half-row {
    grid-template-columns: repeat(5, 1fr) 0.5fr;
} 


.grid-md-2-cols {
    @media (min-width: $grid-breakpoint-md) {
        grid-template-columns: repeat(2, 1fr) !important;
        .grid-loader {
            grid-column: 1 / span 2 !important;
        }
    }
}
.grid-3-cols {
    grid-template-columns: repeat(3, 1fr) !important;
    .grid-loader {
        grid-column: 1 / span 3 !important;
    }
}
.grid-md-3-cols {
    @media (min-width: $grid-breakpoint-md) {
        grid-template-columns: repeat(3, 1fr) !important;
        .grid-loader {
            grid-column: 1 / span 3 !important;
        }
    }
}
.grid-4-cols {
    grid-template-columns: repeat(4, 1fr) !important;
    .grid-loader {
        grid-column: 1 / span 4 !important;
    }
}
.grid-md-4-cols {
    @media (min-width: $grid-breakpoint-md) {
        grid-template-columns: repeat(4, 1fr) !important;
        .grid-loader {
            grid-column: 1 / span 4 !important;
        }
    }
}
.grid-5-cols {
    grid-template-columns: repeat(5, 1fr) !important;
    .grid-loader {
        grid-column: 1 / span 5 !important;
    }
}
.grid-md-5-cols {
    @media (min-width: $grid-breakpoint-md) {
        grid-template-columns: repeat(5, 1fr) !important;
        .grid-loader {
            grid-column: 1 / span 5 !important;
        }
    }
}
.grid-md-6-cols {
    @media (min-width: $grid-breakpoint-md) {
        grid-template-columns: repeat(6, 1fr) !important;
        .grid-loader {
            grid-column: 1 / span 6 !important;
        }
    }
}

.grid-md-7-cols {
    @media (min-width: $grid-breakpoint-md) {
        grid-template-columns: repeat(7, 1fr) !important;
        .grid-loader {
            grid-column: 1 / span 6 !important;
        }
    }
}
@media (max-width: $grid-breakpoint-md) {
    .fixed-5-plus-half-row {
        grid-template-columns: repeat(2, 1fr);
    }
}

.scout-assist-form{
    margin-bottom: 20px;
}

.player-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.player-card {
    border-radius: 8px;
    padding: 1rem;
}

.player-table {
    display: flex;
    flex-flow: row wrap;
    transition: 0.5s;

}

.all-animate  {
    transition: all 300ms ease-out ;
  }
  
.player-table-cell {
    flex-basis: calc(100% / 10);
    
    &.header {        
        flex:0 0 90%;
        @media (min-width: $grid-breakpoint-md) {
            flex:0 0 60%;
        }
    
      }
    .header {
        color:$white;
    }
}
.like-link-underline {
   
   
    text-decoration: $link-hover-decoration !important;
    &:hover {
      //color: var(--#{$prefix}link-hover-color);
      text-decoration: $link-decoration !important;
    }
  }
.player-table-row {

    &:nth-of-type(even) {
        background-color: $dark-mode-500;
    }
    .player-club-cell {
        color: $neutral-gray-700 !important
    }

    &:hover {

        background: $fio-success-400;
        .player-club-cell {
            color: $dark-mode-700 !important
        }
        .player-table-cell {
            color: $dark-mode-700 !important;

            .header {
                color:$dark-mode-700;
            }

            .bg-on-hover {
                background: $crayola-red-800;
                font-weight: $font-weight-semibold;
            }

        }
    }
}



@media (min-width: $grid-breakpoint-md) {


    .d-grid-md {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3rem;
        /* justify-items: center; */
        margin: 0;
        padding: 0;
        grid-auto-rows: 1fr;

        .grid-loader {
            grid-column: 1 / span 1;
        }
             
    }
}

@media (min-width: $grid-breakpoint-lg) {


    .d-grid-lg {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 3rem;
        /* justify-items: center; */
        margin: 0;
        padding: 0;
        grid-auto-rows: 1fr;

        .grid-loader {
            grid-column: 1 / span 1;
        }
             
    }
}

.joiner {
    &::before {
        content: "";
        display: block;
        width: 2px;
        height: 100%;
        background: #D9DEE2;       
        position: absolute;
        top: 0;
        left: calc(50% - .375rem - 3px);
    }

}

.shadow-hover-xss,
.shadow-hover-or-active-xss  {
    box-shadow: none;
    transition:  box-shadow .3s ease !important;
    &:hover,
    &.active {        
        box-shadow:$box-shadow-xxs ;
    }

}
.shadow-hover-xs,
.shadow-hover-or-active-xs {
    box-shadow: none;
    transition:  box-shadow .3s ease !important;
    &:hover,
    &.active {
        box-shadow:$box-shadow-xs ;
    }

}
.shadow-hover-sm,
.shadow-hover-or-active-sm {
    box-shadow: none;
    transition:  box-shadow .3s ease !important;
    &:hover,
    &.active {
        box-shadow:$box-shadow-sm ;
    }

}
.shadow-hover-md,
.shadow-hover-or-active-md {
    box-shadow: none;
    transition:  box-shadow .3s ease !important;
    &:hover,
    &.active {
        box-shadow:$box-shadow ;
    }

}
.shadow-hover-lg,
.shadow-hover-or-active-lg {
    box-shadow: none;
    transition: box-shadow .3s ease !important;
    &:hover,
    &.active {
        box-shadow:$box-shadow-lg ;
    }

}
.shadow-hover-xl,
.shadow-hover-or-active-xl {
    transition:  box-shadow .3s ease !important;
    &:hover,
    &.active {
        box-shadow:$box-shadow-xl ;
    }

}
.black-icon-on-hover,
.black-icon-on-hover-or-active {
    transition: transform, box-shadow .3s ease !important;
    &:hover,
    &.active {
        .icon {
            transform: translate(0.5rem, 0);
            color : $black !important;
        }
    }

    &.p-2 {        
        &:hover,
        &.active {
            .icon {
                transform: translate(0.25rem, 0) !important;
                
            }
        }
    }
}
.dark {
    &:focus,
    &:active {
      @if $enable-shadows {
        @include box-shadow($input-box-shadow, $input-focus-box-shadow);
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly    
        box-shadow: 0 0 1rem .25rem rgba(244, 24, 88, 0.25); 
        //box-shadow: 0 0 0 $input-focus-width rgba(var(--bs-neutral-gray-50-rgb), 0.14) !important ;
      }
    }
  }
.translate-icon-on-hover,
.translate-icon-on-hover-or-active {
    transition: transform, box-shadow .3s ease !important;
    &:hover,
    &.active {
        .icon {
            transform: translate(0.5rem, 0);
        }
        .white {
            color: #fff !important;
        }
    }

    &.p-2 {        
        &:hover,
        &.active {
            .icon {
                transform: translate(0.25rem, 0) !important;
                
            }
        }
    }
}
.white-icon-on-hover,
.white-icon-on-hover-or-active {
    transition: transform, box-shadow .3s ease !important;
    &:hover,
    &.active {      
        .white {
            color: #fff !important;
        }
    }    
}
.border-on-hover,
.border-on-hover-or-active {
    border:solid .125rem transparent;
    &:hover,
    &.active { 
        border-color: $zero-dark-800;
    }
}

.bg-dark-mode-400 {
    &.border-on-hover,
    &.border-on-hover-or-active {
        border:solid .125rem transparent;
        &:hover,
        &.active { 
            border-color: $dark-mode-600;
        }
    }
}

.gradient-overlay {
    background: linear-gradient(0deg, #20232A 34.12%, rgba(32, 35, 42, 0) 126.37%);
}


@media (min-width: $grid-breakpoint-md) {
    .flex-md-auto {
        flex:none;
    
    }
}

.flex-none {
    flex:none;   
}

.jvectormap-container {
    touch-action: unset !important;
    svg {
        touch-action: unset !important; 
    }
}

/* sharethis overwrites */
#st-1 {
    z-index:1 !important;
    .st-btn[data-network='sharethis'] { 
        background-color: transparent  !important;

        &:hover {
            top:unset  !important;
            opacity: 1;
        }
       
    }

    &.st-has-labels {
        .st-btn {
            font-size: 1rem !important;
            line-height: 1.5rem !important;
            min-width: unset !important;
            display: flex !important;
            align-items: center;
            padding:  0;
            height: unset;
            img {
                position: unset;
                margin-right: .5rem;
            }

            span {
                letter-spacing: unset;
                padding:  0;
                text-decoration: underline;
                min-width: unset !important;
            }
        }
    } 

} 

.j-add-tag:focus {
    outline: none;
}
 
.d-grid.show-limited div:nth-child(n+4) {
    display: none;
}

.d-grid.show-limited div:nth-child(3).half-height {
    height: 50%;
    overflow: hidden;
    position: relative;
}

.d-grid.show-limited div:nth-child(3).half-height::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(32, 35, 42, 0), rgba(32, 35, 42, 1));
    z-index: 1;
}

@media (min-width: 768px) {
    .d-grid.show-limited div {
        display: block !important;
    }

    .d-grid.show-limited div:nth-child(3).half-height {
        height: auto;
        overflow: visible;
    }

    .d-grid.show-limited div:nth-child(3).half-height::after {
        content: none;
    }
}
.center-line {
    position: relative;

}
.center-line:before {
    background: #d9dee2;
    bottom: 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: calc(50% - 1px);
    width: 100%;
}
.target-bg {
    position: relative;
    overflow: hidden;

    &::before {
        content: " ";
        position: absolute;
        top: 0;
        right: 30%;
        width: 100%;
        height: 100%;
        background-image: url('../../images/target.svg');
        background-repeat: no-repeat;
        background-size: cover;
        opacity: 0.8;
    }
}

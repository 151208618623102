// stylelint-disable function-name-case

// All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251
@each $color, $value in $theme-colors {
  $color-rgb: to-rgb($value);
  .text-bg-#{$color} {
    color: color-contrast($value) if($enable-important-utilities, !important, null);
    background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
  }
}

@each $color, $value in $theme-colors {
  $color-rgb: to-rgb($value);
  .bg-hover-#{$color} {

    &:hover,
    &:focus {
      background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
    }     
  }
}
@each $color, $value in $fio-palette {
  $color-rgb: to-rgb($value);
  .bg-hover-#{$color} {

    &:hover,
    &:focus {
      background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
    }     
  }
}

@each $color, $value in $theme-colors {
  $color-rgb: to-rgb($value);
  .bg-active-#{$color} {

    &.active {
      background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
    }     
  }
}
@each $color, $value in $fio-palette {
  $color-rgb: to-rgb($value);
  .bg-active-#{$color} {

    &.active {
      background-color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
    }     
  }
}


@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/inter/Inter-Light.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Light.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../../fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Bold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("../../fonts/inter/Inter-Thin.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Thin.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("../../fonts/inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("../../fonts/inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("../../fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("../../fonts/inter/Inter-LightItalic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-LightItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("../../fonts/inter/Inter-Italic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Italic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../fonts/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("../../fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("../../fonts/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("../../fonts/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("../../fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("../../fonts/inter/Inter-Black.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-Black.woff?v=3.19") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("../../fonts/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
    url("../../fonts/inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}

/* -------------------------------------------------------
  Variable font.
  Usage:

    html { font-family: 'Inter', sans-serif; }
    @supports (font-variation-settings: normal) {
      html { font-family: 'Inter var', sans-serif; }
    }
  */
@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: 'Regular';
  src: url("../../fonts/inter/Inter-roman.var.woff2?v=3.19") format("woff2");
}

@font-face {
  font-family: 'Inter var';
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: 'Italic';
  src: url("../../fonts/inter/Inter-italic.var.woff2?v=3.19") format("woff2");
}



/* --------------------------------------------------------------------------
  [EXPERIMENTAL] Multi-axis, single variable font.

  Slant axis is not yet widely supported (as of February 2019) and thus this
  multi-axis single variable font is opt-in rather than the default.

  When using this, you will probably need to set font-variation-settings
  explicitly, e.g.

    * { font-variation-settings: "slnt" 0deg }
    .italic { font-variation-settings: "slnt" 10deg }

  */
@font-face {
  font-family: 'Inter var experimental';
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url("../../fonts/inter/Inter.var.woff2?v=3.19") format("woff2");
}

html,
body,
p,
div,
section,
li,
ul ol,
span {
  font-family: 'Inter', Arial, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Inter', Arial, sans-serif !important;
}

p {


  font-size: $paragraph-base-size ;
  line-height: $paragraph-base-height;

  &.loose {
    line-height: 1.625rem;

    small {
      line-height: 1.2375rem;
    }
  }



}

.small,
small {
  font-size: $small-font-size;
  line-height: $small-line-height;

  @media (max-width: $grid-breakpoint-md) {
    font-size: $paragraph-extra-small-size !important;
    line-height: $paragraph-extra-small-height !important;
  }

}

.extra-small {
  font-size: $paragraph-extra-small-size;
  line-height: $paragraph-extra-small-height;
}


.footer,
.caption {
  font-size: $paragraph-extra-small-size;
}

.footer {
  line-height: $footer-height;
}

.caption {
  line-height: $caption-height;
}

.fa,
.fa-light,
.fa-solid {
  &.large {
    font-size: 5rem;
  }
}

.display-1 {
  font-size: map-get($display-font-sizes, 1) !important;
  line-height: map-get($display-font-heights, 1) !important;
}

.display-2 {
  font-size: map-get($display-font-sizes, 2) !important;
  line-height: map-get($display-font-heights, 2) !important;
}

.display-3 {
  font-size: map-get($display-font-sizes, 3) !important;
  line-height: map-get($display-font-heights, 3) !important;
}

.display-4 {
  font-size: map-get($display-font-sizes, 4) !important;
  line-height: map-get($display-font-heights, 4) !important;
}

.display-5 {
  font-size: map-get($display-font-sizes, 5) !important;
  line-height: map-get($display-font-heights, 5) !important;
}

.display-6 {
  font-size: map-get($display-font-sizes, 6) !important;
  line-height: map-get($display-font-heights, 6) !important;
}


.h1,
h1 {
  font-size: map-get($heading-font-sizes, 1) !important;
  line-height: map-get($heading-font-heights, 1) !important;

  small {
    font-size: 2.0625rem !important;
    line-height: 2.5rem;
  }
}

.h2,
h2 {
  font-size: map-get($heading-font-sizes, 2) !important;
  line-height: map-get($heading-font-heights, 2) !important;

  small {
    font-size: 1.175rem !important;
    line-height: 2.125rem;
  }
}

.h3,
h3 {
  font-size: map-get($heading-font-sizes, 3) !important;
  line-height: map-get($heading-font-heights, 3) !important;

  small {
    font-size: 1.4375rem !important;
    line-height: 1.75rem;
  }
}

.h4,
h4 {
  font-size: map-get($heading-font-sizes, 4) !important;
  line-height: map-get($heading-font-heights, 4) !important;

  small {
    font-size: 1.1875rem !important;
    line-height: 2.4375rem;
  }
}

.h5,
h5 {
  font-size: map-get($heading-font-sizes, 5) !important;
  line-height: map-get($heading-font-heights, 5) !important;

  small {
    font-size: 1rem !important;
    line-height: 1.1875rem;
  }
}

.h6,
h6 {
  font-size: map-get($heading-font-sizes, 6) !important;
  line-height: map-get($heading-font-heights, 6) !important;

  small {
    font-size: 0.875rem !important;
    line-height: 1.0625rem;
  }
}

p,
a {
  &.large {
    font-size: $paragraph-large-size;
    line-height: $paragraph-large-height;

    @media (max-width: $grid-breakpoint-md) {
      font-size: $paragraph-base-size ;
      line-height: $paragraph-base-height;
    }

    &.loose {
      line-height: 1.625rem;
    }

  }

  &.extra-large {
    font-size: $paragraph-extra-large-size;
    line-height: $paragraph-extra-large-height;
  }
}


@media (max-width: $grid-breakpoint-md) {
  .display-1 {
    font-size: map-get($display-font-sizes, 2) !important;
    line-height: map-get($display-font-heights, 2) !important;
  }

  .display-2 {
    font-size: map-get($display-font-sizes, 3) !important;
    line-height: map-get($display-font-heights, 3) !important;
  }

  .display-3 {
    font-size: map-get($display-font-sizes, 4) !important;
    line-height: map-get($display-font-heights, 4) !important;
  }

  .display-4 {
    font-size: map-get($display-font-sizes, 5) !important;
    line-height: map-get($display-font-heights, 5) !important;
  }

  .display-5 {
    font-size: map-get($display-font-sizes, 6) !important;
    line-height: map-get($display-font-heights, 6) !important;
  }

  .display-6 {
    font-size: map-get($heading-font-sizes, 1) !important;
    line-height: map-get($heading-font-heights, 1) !important;
  }

  .h1,
  h1 {
    font-size: map-get($heading-font-sizes, 2) !important;
    line-height: map-get($heading-font-heights, 2) !important;
  }

  .h2,
  h2 {
    font-size: map-get($heading-font-sizes, 3) !important;
    line-height: map-get($heading-font-heights, 3) !important;
  }

  .h3,
  h3 {
    font-size: map-get($heading-font-sizes, 4) !important;
    line-height: map-get($heading-font-heights, 4) !important;
  }

  .h4,
  h4 {
    font-size: map-get($heading-font-sizes, 5) !important;
    line-height: map-get($heading-font-heights, 5) !important;
  }

  .h5,
  h5 {
    font-size: map-get($heading-font-sizes, 6) !important;
    line-height: map-get($heading-font-heights, 6) !important;
  }

  .h6,
  h6 {
    font-size: $small-font-size !important;
    line-height: $h6-tablet-height !important;
  }

  p,
  a {

    /*&.large {
        font-size: $paragraph-base-size ;
        line-height: $paragraph-base-height;
        &.loose {
          line-height: $paragraph-base-height;
        }
  
      }*/
    &.extra-large {
      font-size: $paragraph-large-size ;
      line-height: $paragraph-large-height;
    }
  }
}

@media (max-width: $grid-breakpoint-sm) {
  .display-1 {
    font-size: map-get($display-font-sizes, 3) !important;
    line-height: map-get($display-font-heights, 3) !important;
  }

  .display-2 {
    font-size: map-get($display-font-sizes, 4) !important;
    line-height: map-get($display-font-heights, 4) !important;
  }

  .display-3 {
    font-size: map-get($display-font-sizes, 5) !important;
    line-height: map-get($display-font-heights, 5) !important;
  }

  .display-4 {
    font-size: map-get($display-font-sizes, 6) !important;
    line-height: map-get($display-font-heights, 6) !important;
  }

  .display-5 {
    font-size: map-get($heading-font-sizes, 1) !important;
    line-height: map-get($heading-font-heights, 1) !important;
  }

  .display-6 {
    font-size: map-get($heading-font-sizes, 2) !important;
    line-height: map-get($heading-font-heights, 2) !important;
  }

  .h1,
  h1 {
    font-size: map-get($heading-font-sizes, 3) !important;
    line-height: map-get($heading-font-heights, 3) !important;
  }

  .h2,
  h2 {
    font-size: map-get($heading-font-sizes, 4) !important;
    line-height: map-get($heading-font-heights, 4) !important;
  }

  .h3,
  h3 {
    font-size: map-get($heading-font-sizes, 5) !important;
    line-height: map-get($heading-font-heights, 5) !important;
  }

  .h4,
  h4 {
    font-size: map-get($heading-font-sizes, 6) !important;
    line-height: map-get($heading-font-heights, 6) !important;
  }

  .h5,
  h5 {
    font-size: $small-font-size !important;
    line-height: $h5-mobile-height !important;
  }

  .h6,
  h6 {
    font-size: $paragraph-extra-small-size !important;
    line-height: $h6-mobile-height;
  }

 
  
}

.sub-heading {
  font-size: 1.25rem !important;
  line-height: 1.5rem;
}
.more-products {
  .medium-large{
    font-size: $paragraph-large-size;
    line-height: $paragraph-large-height;
  }

  @media (max-width: $grid-breakpoint-sm) {
    .extra-large {
      font-size: $paragraph-base-size ;
      line-height: $paragraph-base-height;
    }
    .large {
      font-size: $paragraph-base-size ;
      line-height: $paragraph-base-height;
    }
    .ultra-small {
      font-size: $paragraph-ultra-small-size ;
      line-height: $paragraph-ultra-small-height ;
    }
    p {
      font-size: $paragraph-extra-small-size ;
      line-height: $paragraph-extra-small-height;
    
    }
    .accordion-button{
      font-size: $small-font-size;
      line-height: $small-line-height;
    }
    .medium-large{
      font-size: $small-font-size;
      line-height: $small-line-height;
    }
  }
}

// desktop:16px / mobile: 14px
.regular{
  p {
    font-size: $paragraph-base-size ;
    line-height: $paragraph-base-height;
  }
  @media (max-width: $grid-breakpoint-sm) {
    p {
      font-size: $small-font-size ;
      line-height: $small-line-height;
    }
  }
}


// stylelint-disable function-name-case

// All-caps `RGBA()` function used because of this Sass bug: https://github.com/sass/node-sass/issues/2251


@each $color, $value in $theme-colors {
  $color-rgb: to-rgb($value);
  .text-hover-or-active-#{$color} {

    &.active,
    &:hover  {
      color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
    }
  }
}
@each $color, $value in $fio-palette {
  $color-rgb: to-rgb($value);
  .text-hover-or-active-#{$color} {

    &.active,
    &:hover  {
      color: RGBA($color-rgb, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
    }
  }
}

.text-hover-or-active-white {

  &.active,
  &:hover  {
    color: RGBA($white, var(--#{$prefix}bg-opacity, 1)) if($enable-important-utilities, !important, null);
  }
}



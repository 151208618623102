#loader-2 span,
.loader-2 span,
button .loader span,
.btn .loader span,
.fenix-btn .loader span {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: $crayola-red-400;
  margin: 35px 5px;
  -webkit-box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
  -moz-box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
  box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
}

#loader-2 span:nth-child(1),
.loader-2 span:nth-child(1),
button .loader span:nth-child(1),
a.btn .loader span:nth-child(1),
a.fenix-btn .loader span:nth-child(1) {
  animation: bounce 1s ease-in-out infinite;
  background-color: #FFFFFF;
}

button .loader span:nth-child(1),
a.btn  .loader span:nth-child(1),
a.fenix-btn  .loader span:nth-child(1) {
  animation: bouncebutton 1s ease-in-out infinite;
}

#loader-2 span:nth-child(2),
.loader-2 span:nth-child(2),
button .loader span:nth-child(2),
.btn .loader span:nth-child(2),
.fenix-btn .loader span:nth-child(2) {
  animation: bounce 1s ease-in-out 0.33s infinite ;
  background-color: $crayola-red-400;
}

button .loader span:nth-child(2),
a.btn .loader span:nth-child(2),
a.fenix-btn .loader span:nth-child(2) {
  animation: bouncebutton 1s ease-in-out 0.33s infinite !important;
}

#loader-2 span:nth-child(3),
.loader-2 span:nth-child(3),
button .loader span:nth-child(3),
a.btn .loader span:nth-child(3),
a.fenix-btn .loader span:nth-child(3) {
  animation: bounce 1s ease-in-out 0.66s infinite;
  background-color: #FFFFFF;
}

button .loader span:nth-child(3),
a.btn .loader span:nth-child(3),
a.fenix-btn .loader span:nth-child(3) {
  animation: bouncebutton 1s ease-in-out 0.66s infinite;
}

@keyframes bounce {
0%, 75%, 100% {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

25% {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -o-transform: translateY(-20px);
  transform: translateY(-20px);
}
}

@keyframes bouncebutton {
0%, 75%, 100% {
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);
  transform: translateY(5px);
}

25% {
  -webkit-transform: translateY(-5px);
  -ms-transform: translateY(-5px);
  -o-transform: translateY(-5px);
  transform: translateY(-5px);
}
}
// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons


// scss-docs-start btn-variant-mixin
@mixin button-variant(
  $background,
  $border,
  $background-calc :  rgba($background,0.8),
  $border-calc: rgba($background,0.8),
  $color: if($background == $safety-orange , $white ,color-contrast($background)),
  $hover-background: rgba($background,0.6),
  $hover-border: rgba($background,0.6),
  $hover-color: if($background == $safety-orange , $white,color-contrast($hover-background)),
  $active-background: $white,
  $active-border: if($color == $color-contrast-light, shade-color($border, $btn-active-border-shade-amount), tint-color($border, $btn-active-border-tint-amount)),
  $active-color: if($background == $safety-orange , $white,color-contrast($hover-background)),
  $disabled-background: rgba($background,0.3),
  $disabled-border: rgba($background,0.3),
  $disabled-color:  $color
) {
  --#{$prefix}btn-color: #{$color};
  --#{$prefix}btn-bg: #{$background-calc};
  --#{$prefix}btn-border-color: #{$border-calc};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb(mix($color, $border, 15%))};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #000;
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-bg: #{$disabled-background};
  --#{$prefix}btn-disabled-border-color: #{$disabled-border};
}

@mixin button-variant-fio(
  $color,
  $background,
  $background-calc : if($color == $primary, map-get($theme-colors-effects, #{$color}-500), map-get($theme-colors-effects, #{$color}-500)),
  $border-calc:  if($color == $primary, map-get($theme-colors-effects, #{$color}-500), map-get($theme-colors-effects, #{$color}-500)),
  $text-color:  if($color == "tertiary" , $black , $white),
  $hover-background: if($color == 'primary', map-get($theme-colors-effects, #{$color}-700), map-get($theme-colors-effects, #{$color}-600)),
  $hover-border: if($color == 'primary', map-get($theme-colors-effects, #{$color}-700), map-get($theme-colors-effects, #{$color}-600)),
  $hover-color: if($color == "tertiary" , $black , $white),
  $active-background: map-get($theme-colors-effects, #{$color}-600),
  $active-border:  map-get($theme-colors-effects, #{$color}-600),
  $active-color: $hover-color,
  $disabled-background: map-get($theme-colors-effects, #{$color}-300),
  $disabled-border: map-get($theme-colors-effects, #{$color}-300),
  $disabled-color:  $text-color
) {
  --#{$prefix}btn-color: #{$text-color};
  --#{$prefix}btn-bg: #{$background-calc};
  --#{$prefix}btn-border-color: #{$border-calc};
  --#{$prefix}btn-hover-color: #{$hover-color};
  --#{$prefix}btn-hover-bg: #{$hover-background};
  --#{$prefix}btn-hover-border-color: #{$hover-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb(mix($text-color, $active-border, 15%))};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-bg: #{$disabled-background};
  --#{$prefix}btn-disabled-border-color: #{$disabled-border};
}
// scss-docs-end btn-variant-mixin




// scss-docs-start btn-outline-variant-mixin
@mixin button-outline-variant(
  $color,
  $color-calc: map-get($theme-colors-effects, #{$color}-400),
  $color-hover: map-get($theme-colors-effects, #{$color}-500),
  $active-background: #fff,
  $active-border:map-get($theme-colors-effects, #{$color}-500),
  $active-color: map-get($theme-colors-effects, #{$color}-500),
  $disabled-border: map-get($theme-colors-effects, #{$color}-200),
  $disabled-color: map-get($theme-colors-effects, #{$color}-200)
) {
  --#{$prefix}btn-color: #{$color-calc};
  --#{$prefix}btn-border-color: #{$color-calc};
  --#{$prefix}btn-hover-color: #{$color-hover};
  --#{$prefix}btn-hover-bg: #{$active-background};
  --#{$prefix}btn-hover-border-color: #{$active-border};
  --#{$prefix}btn-focus-shadow-rgb: #{to-rgb($active-color)};
  --#{$prefix}btn-active-color: #{$active-color};
  --#{$prefix}btn-active-bg: #{$active-background};
  --#{$prefix}btn-active-border-color: #{$active-border};
  --#{$prefix}btn-active-shadow: #{$btn-active-box-shadow};
  --#{$prefix}btn-disabled-color: #{$disabled-color};
  --#{$prefix}btn-disabled-bg: #{$active-background};
  --#{$prefix}btn-disabled-border-color: #{$disabled-border};
  --#{$prefix}gradient: none;
}
// scss-docs-end btn-outline-variant-mixin

// scss-docs-start btn-size-mixin
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius, $line-height) {
  --#{$prefix}btn-padding-y: #{$padding-y};
  --#{$prefix}btn-padding-x: #{$padding-x};
  @include rfs($font-size, --#{$prefix}btn-font-size);
  --#{$prefix}btn-border-radius: #{$border-radius};
  --#{$prefix}btn-line-height: #{$line-height};
}
// scss-docs-end btn-size-mixin
